import Error404 from "src/pages/404";
import {authRoutes} from "src/routes/auth";
import {actionRoutes} from "src/routes/action";
import {dashboardRoutes} from "src/routes/dashboard";
import {SurveyEngineLayout} from "src/layouts/survey-engine/survey-engine-layout";
import SurveyEngine from "src/pages/survey/survey-engine";
import {mainRoutes} from "./main";

export const routes = [
  {
    path: "survey",
    children: [
      {
        path: ":uuid",
        element: (
          <SurveyEngineLayout>
            <SurveyEngine />
          </SurveyEngineLayout>
        ),
      },
    ],
  },
  ...mainRoutes,
  ...authRoutes,
  ...actionRoutes,
  ...dashboardRoutes,
  {
    path: "*",
    element: <Error404 />,
  },
];
