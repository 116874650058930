import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {useSurveyEngine} from "src/hooks/use-survey-engine";

export const ShortTextQuestion = props => {
  const {question} = props;
  const surveyEngine = useSurveyEngine();
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    const prevAnswer = surveyEngine.submission.answersMap[question.id];
    if (prevAnswer) {
      setAnswer(prevAnswer.text);
    }
  }, []);

  const handleAnswer = e => {
    const answerText = e.target.value;
    setAnswer(answerText);
    surveyEngine.handleQuestionAnswer(question, answerText);
  };

  return (
    <TextField
      value={answer}
      onChange={handleAnswer}
      variant="outlined"
      fullWidth={true}
    />
  );
};
