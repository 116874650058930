import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import InfoIcon from "@mui/icons-material/Info";
import {useTranslation} from "react-i18next";
import {useAuth} from "src/hooks/use-auth";
import CircularProgress from "@mui/material/CircularProgress";
import {actionService} from "src/services/action";
import {GifSplashScreen} from "src/components/gif-splash-screen";

function AccountInfoCommunicationForm() {
  const {t} = useTranslation();
  const auth = useAuth();
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const [preferences, setPreferences] = useState(null);

  useEffect(() => {
    actionService.getNotifications(auth.user.user_token).then(response => {
      setPreferences({
        email_notification_enabled: response.email_notification_enabled,
        sms_notification_enabled: response.sms_notification_enabled,
      });
    });
  }, []);

  if (preferences === null) {
    return <GifSplashScreen />;
  }

  const handleToggle = event => {
    setPreferences({...preferences, [event.target.name]: event.target.checked});
  };

  const handleSavePreferences = () => {
    setIsSaveButtonLoading(true);
    actionService.updateNotifications(auth.user.user_token, preferences).then(_ => {
      setIsSaveButtonLoading(false);
    });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        m: 3,
        mt: 0,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("editInfo.communicationPreferencesDirective")}
      </Typography>

      <Grid>
        {/* Email Notifications */}
        <Grid item xs={12} mb={2}>
          <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
            <EmailIcon sx={{mr: 1, color: "#3f51b5"}} />
            <Typography variant="h6">{t("editInfo.emailNotifications")}</Typography>
            <Tooltip title={t("editInfo.receiveEmailNotification")} arrow>
              <IconButton size="small" sx={{ml: 1}}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={preferences.email_notification_enabled}
                onChange={handleToggle}
                name="email_notification_enabled"
              />
            }
            label={t("enabled")}
          />
        </Grid>

        {/* SMS Notifications */}
        <Grid item xs={12} mb={2}>
          <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
            <SmsIcon sx={{mr: 1, color: "#f50057"}} />
            <Typography variant="h6">{t("editInfo.SMSNotifications")} </Typography>
            <Tooltip title={t("editInfo.receiveSMSNotification")} arrow>
              <IconButton size="small" sx={{ml: 1}}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={preferences.sms_notification_enabled}
                onChange={handleToggle}
                name="sms_notification_enabled"
              />
            }
            label={t("enabled")}
          />
        </Grid>

        {/* Newsletter Subscription */}
        {/*<Grid item xs={12} mb={2}>*/}
        {/*  <Box sx={{display: "flex", alignItems: "center", mb: 2}}>*/}
        {/*    <NewspaperIcon sx={{mr: 1, color: "#ff9800"}} />*/}
        {/*    <Typography variant="h6">{t("editInfo.newsletterSubscription")} </Typography>*/}
        {/*    <Tooltip title={t("editInfo.receiveOurUpdates")} arrow>*/}
        {/*      <IconButton size="small" sx={{ml: 1}}>*/}
        {/*        <InfoIcon fontSize="small" />*/}
        {/*      </IconButton>*/}
        {/*    </Tooltip>*/}
        {/*  </Box>*/}
        {/*  <FormControlLabel*/}
        {/*    control={*/}
        {/*      <Switch*/}
        {/*        checked={preferences.newsletter}*/}
        {/*        onChange={handleToggle}*/}
        {/*        name="newsletter"*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label={t("enabled")}*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>

      <Button onClick={handleSavePreferences} color="primary" variant="contained">
        {isSaveButtonLoading ? <CircularProgress size={24} color="inherit" /> : t("save")}
      </Button>
    </Box>
  );
}

export default AccountInfoCommunicationForm;
