export class Submission {
  constructor(submission) {
    this.isCompleted = submission.is_completed;
    this.answersMap = this.generateAnswerMap(submission.answers);
  }

  generateAnswerMap(answers) {
    return answers.reduce(
      (acc, answer) => ({[answer.question_id]: new Answer(answer)}),
      {},
    );
  }

  extendAnswers(answers) {
    this.answersMap = {
      ...this.answersMap,
      ...this.generateAnswerMap(answers),
    };
  }
}

export class Answer {
  constructor(answerData) {
    this.id = answerData.id;
    this.text = answerData.text;
    this.question = answerData.question_id;
    this.embedded_text = answerData.embedded_text;
  }
}
