export const rewardType = {
  CASH: "cash",
  MIGROS: "migros",
  A101: "a101",
};

export const rewardRequestStatus = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
};
