import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {useSurveyEngine} from "src/hooks/use-survey-engine";

const CheckBoxOptions = props => {
  const {question, options} = props;
  const [selectedOptions, setSelectedOptions] = useState([]);

  const surveyEngine = useSurveyEngine();

  useEffect(() => {
    const prevAnswer = surveyEngine.submission.answersMap[question.id];
    if (prevAnswer) {
      const prevSelectedOptions = {};
      for (const val of prevAnswer.text.split("---")) {
        prevSelectedOptions[val] = true;
      }
      setSelectedOptions(prevAnswer.text);
    }
  }, []);

  const handleAnswer = event => {
    const newSelectedOptions = {
      ...selectedOptions,
      [event.target.value]: event.target.checked,
    };
    setSelectedOptions(newSelectedOptions);
    const checkedOptions = Object.keys(newSelectedOptions).filter(
      opt => newSelectedOptions[opt] === true,
    );
    const extra = {
      options_order: options.map(opt => opt.id).join(","),
    };
    surveyEngine.handleQuestionAnswer(question, checkedOptions.join("---"), extra);
  };

  return (
    <FormGroup value={selectedOptions} onChange={handleAnswer}>
      {options.map(option => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Checkbox checked={selectedOptions[option.text] ?? false} />}
          label={option.text}
        />
      ))}
    </FormGroup>
  );
};

const RadioOptions = props => {
  const {question, options} = props;
  const [selectedOption, setSelectedOption] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const {t} = useTranslation();
  const surveyEngine = useSurveyEngine();

  useEffect(() => {
    const prevAnswer = surveyEngine.submission.answersMap[question.id];
    if (prevAnswer) {
      setSelectedOption(prevAnswer.text);
    }
  }, []);

  const handleAnswer = (event, otherSelected) => {
    const answerText = event.target.value;
    setSelectedOption(answerText);
    setIsOtherSelected(otherSelected);
    const extra = {
      options_order: options.map(opt => opt.id).join(","),
    };
    surveyEngine.handleQuestionAnswer(question, answerText, extra);
  };

  const handleRadioOtherOption = event => {
    setSelectedOption("");
    setIsOtherSelected(!isOtherSelected);
    surveyEngine.handleQuestionAnswer(question, "");
  };

  return (
    <RadioGroup value={selectedOption}>
      {options.map(option => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio onClick={handleAnswer} />}
          label={option.text}
        />
      ))}
      {question.other_option && (
        <FormControlLabel
          value={"other"}
          checked={isOtherSelected}
          control={<Radio onClick={handleRadioOtherOption} />}
          label={
            isOtherSelected ? (
              <TextField
                fullWidth
                placeholder={t("other")}
                value={selectedOption}
                onChange={e => handleAnswer(e, true)}
                inputProps={{
                  style: {
                    padding: 8,
                  },
                }}
              />
            ) : (
              t("other")
            )
          }
        />
      )}
    </RadioGroup>
  );
};

export const ListOptions = props => {
  const {question, options} = props;

  return (
    <FormControl>
      {question.allow_multiple_answers ? (
        <CheckBoxOptions question={question} options={options} />
      ) : (
        <RadioOptions question={question} options={options} />
      )}
    </FormControl>
  );
};
