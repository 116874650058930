import {questionType} from "src/schemas/survey/question/question-types";
import {MultipleChoiceQuestion} from "src/pages/survey/question-factory/multiple-choice-question";
import {ShortTextQuestion} from "src/pages/survey/question-factory/short-text-question";

export const QuestionFactory = props => {
  const {question} = props;

  switch (question.type) {
    case questionType.MultipleChoice:
      return <MultipleChoiceQuestion question={question} />;
    case questionType.ShortText:
      return <ShortTextQuestion question={question} />;
    case questionType.TextGraphic:
      return <></>;
    default:
      return <>Not Implemented Question</>;
  }
};
