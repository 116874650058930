import axiosInstance from "src/services/axiosInstance";
import {TeamMemberBankDetails} from "src/entities/team/team-member";

class TeamMemberService {
  async listTeamMemberRewardRequests(page) {
    return await axiosInstance
      .get("/team-members/reward-requests", {params: {page: page}})
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async getTeamMemberRewardsStats() {
    return await axiosInstance
      .get("/team-members/rewards/stats")
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async createTeamMemberRewardRequests(payload) {
    return await axiosInstance
      .post("/team-members/reward-requests", payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async getTeamMembersInfo() {
    return await axiosInstance
      .get("/team-members/info")
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async saveTeamMembersInfo(data) {
    return await axiosInstance
      .put("/team-members/info", data)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async getBankDetails() {
    return await axiosInstance
      .get("/team-members/bank-details")
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async saveBankDetails(data) {
    return await axiosInstance
      .patch("/team-members/bank-details", data)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const teamMemberService = new TeamMemberService();
