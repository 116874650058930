export class User {
  constructor(response) {
    this.email = response.email;
    this.phone = response.phone;
    this.access_token = response.access;
    this.user_token = response.user_token;
    this.is_authenticated = true;
    this.is_initialized = true;
  }

  static createDefaultUser() {
    const user = new User({});
    user.is_authenticated = false;
    user.is_initialized = false;
    return user;
  }

  clone() {
    return new User({
      email: this.email,
      phone: this.phone,
      access: this.access_token,
      user_token: this.user_token,
      is_authenticated: this.is_authenticated,
      is_initialized: this.is_initialized,
    });
  }
}
