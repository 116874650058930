import Container from "@mui/material/Container";
import ContactForm from "./contact-form";

const Contact = () => {
  return (
    <Container sx={{minHeight: "100svh"}} maxWidth={"lg"}>
      <ContactForm />
    </Container>
  );
};

export default Contact;
