import {useState} from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import {
  ExpandMore,
  AccountCircle,
  Computer,
  Campaign,
  Payment,
  Poll,
  CardGiftcard,
  Store,
  AccessTime,
  People,
  PhoneAndroid,
  ArrowForward,
} from "@mui/icons-material";
import {paths} from "src/pages/paths";
import {useTranslation} from "react-i18next";


const faqCategories = {
  account: [
    {
      question: "Birden Fazla Üyelik Hesabı Açabilir Miyim?",
      answer:
        "Her kullanıcımıza özel tek bir hesap sunuyoruz. Böylece tüm kazançlarınız ve hediye puanlarınız tek hesapta güvenle birikir. Birden fazla hesap açılması durumunda sistem bunu tespit eder ve maalesef birikimlerin korunması mümkün olmaz.",
      icon: AccountCircle,
    },
    {
      question: "Şifremi nasıl değiştirebilirim?",
      answer:
        "Hesap ayarlarınızdan şifrenizi güncelleyebilirsiniz. Şifrenizi unuttuysanız, giriş sayfasındaki 'Şifremi Unuttum' seçeneğini kullanabilirsiniz.",
      icon: Computer,
    },
    {
      question: "Bildirim tercihlerimi nasıl değiştirebilirim?",
      answer:
        "Hesap ayarlarınızdan e-posta ve diğer bildirim tercihlerinizi özelleştirebilirsiniz. Yeni anketler, ödüller ve özel teklifler hakkında haberdar olmak için bildirimlerinizi açık tutmanızı öneririz.",
      icon: Campaign,
    },
    {
      question: "Ödeme Alma Bilgilerimi Nasıl Güncellerim?",
      answer:
        "Hesap ayarlarındaki 'Ödeme Bilgileri' bölümünden banka hesap bilgilerinizi güncelleyebilirsiniz. Güvenlik için her güncelleme SMS onayı gerektirir.",
      icon: Payment,
    },
  ],
  surveys: [
    {
      question: "Anketlere kimler katılabilir?",
      answer:
        "18 yaşını doldurmuş, sisteme kayıtlı tüm kullanıcılarımız anketlere katılabilir. Bazı anketler belirli demografik özelliklere sahip katılımcılar için özelleştirilmiş olabilir.",
      icon: People,
    },
    {
      question: "Yeni anket ve fırsatlardan nasıl haberdar olabilirim?",
      answer:
        "Yeni anketlerden haberdar olmak için: 'Hesap Bilgileri > İletişim Tercihleri' sekmesine girerek bildirimlerini açık tutabilir, e-posta bültenimize kayıt olabilir ve sosyal medya hesaplarımızı (Instagram, Twitter, Facebook) takip edebilirsiniz.",
      icon: Poll,
    },
    {
      question: "Mobil Cihazdan Anketleri Doldurabilir Miyim?",
      answer:
        "Evet, anketlerimizi mobil cihazlardan (telefon ve tablet) kolayca doldurabilirsiniz.",
      icon: PhoneAndroid,
    },
  ],
  giftCodes: [
    {
      question: "Hediye kodlarını nasıl kullanabilirim?",
      answer:
        "Hediye kodlarınızı anlaşmalı mağazalarda veya online platformlarda kullanabilirsiniz. Detaylı kullanım kılavuzu için 'Hediye Kodları Nasıl Kullanılır' sayfasını ziyaret edebilirsiniz.",
      icon: CardGiftcard,
      link: paths.dashboard.howtousegiftcodes,
    },
    {
      question: "Hediye kodlarımın geçerlilik süresi ne kadar?",
      answer:
        "Hediye kodlarının geçerlilik süresi mağazaya göre değişiklik gösterebilir. Kodunuzun geçerlilik süresini Hesabım > Hediye Kodlarım bölümünden kontrol edebilirsiniz.",
      icon: AccessTime,
    },
    {
      question: "Hediye kodumu kaybettim, ne yapmalıyım?",
      answer:
        "Hesabım > Hediye Kodlarım bölümünden tüm aktif kodlarınızı görüntüleyebilirsiniz. Sorun yaşamanız durumunda bizimle geçebilirsiniz.",
      icon: Store,
    },
  ],
};

export const GeneralFAQ = () => {
  const {t} = useTranslation();

  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const categories = [
    {label: t("faq.accountOperations"), value: "account", icon: AccountCircle},
    {label: t("faq.surveys"), value: "surveys", icon: Poll},
    {label: t("faq.giftCodes"), value: "giftCodes", icon: CardGiftcard},
  ];

  return (
    <Container maxWidth="lg" sx={{py: 6}}>
      {/* Header Section */}
      <Box textAlign="center" mb={6}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          fontWeight="bold"
          color="primary"
          sx={{
            fontSize: {xs: "2rem", md: "3rem"},
            mb: 3,
          }}
        >
          {t("faq.faqTitle")}
        </Typography>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{maxWidth: "800px", mx: "auto", mb: 4}}
        >
          {t("faq.faqDescr")}
        </Typography>
      </Box>

      {/* Category Tabs */}
      <Paper
        elevation={0}
        sx={{
          maxWidth: "800px",
          mx: "auto",
          mb: 4,
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          overflow: "hidden",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{borderBottom: `1px solid ${theme.palette.divider}`}}
        >
          {categories.map((category, index) => (
            <Tab
              key={category.value}
              icon={<category.icon />}
              label={category.label}
              sx={{
                minHeight: 72,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "medium",
              }}
            />
          ))}
        </Tabs>

        {/* FAQ Accordions */}
        <Box sx={{p: 3}}>
          {faqCategories[categories[selectedTab].value].map((faq, index) => {
            const Icon = faq.icon;
            return (
              <Accordion
                key={index}
                sx={{
                  mb: 2,
                  borderRadius: "8px !important",
                  "&:before": {display: "none"},
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.divider}`,
                  "&:first-of-type": {borderRadius: "8px"},
                  "&:last-of-type": {borderRadius: "8px"},
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.02)"},
                  }}
                >
                  <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                    <Icon color="primary" />
                    <Typography variant="subtitle1" fontWeight="500">
                      {faq.question}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{mb: faq.link ? 2 : 0}}
                  >
                    {faq.answer}
                  </Typography>
                  {faq.link && (
                    <Link
                      href={faq.link}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: "primary.main",
                        textDecoration: "none",
                        "&:hover": {textDecoration: "underline"},
                      }}
                    >
                      Detaylı bilgi
                      <ArrowForward fontSize="small" />
                    </Link>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Paper>
    </Container>
  );
};

export default GeneralFAQ;
