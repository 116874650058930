import Box from "@mui/material/Box";

export const GifSplashScreen = props => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "background.paper",
        flexDirection: "column",
        top: 0,
        height: "100svh",
        width: "100%",
        zIndex: 1400,
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
        }}
      >
        <img
          className="logo"
          src={"/galata_gif.gif"}
          alt="Galata Anket logo"
          width="100%"
          height="100%"
          style={{
            objectFit: "contain",
            maxHeight: 300,
          }}
        />
      </Box>
    </Box>
  );
};
