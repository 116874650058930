import {Grid} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/system";
import {useTranslation} from "react-i18next";

const About = () => {
  const {t} = useTranslation();

  return (
    <Container sx={{minHeight: "100svh", px: {xs: 4, md: 2}}} maxWidth="lg">
      <Grid container spacing={{xs: 2, md: 2}}>
        <Grid item xs={12} md={6}>
          <img
            src="/assets/img/about-first.png"
            alt="About First"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: {xs: "300px", md: "450px"},
              margin: "25px 0",
              objectFit: "contain",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
          <Stack spacing={3} sx={{alignItems: "center"}}>
            <Typography variant="h4" color="textPrimary" textAlign="center">
              {t("professionalSurveyTeam")}
            </Typography>
            <Typography variant="body1" color="textPrimary" textAlign="center">
              {t("dedicatedResearchAndAnalysis")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} order={{xs: 4, md: 3}} container justifyContent="center" alignItems="center">
          <Stack spacing={3} sx={{alignItems: "center"}}>
            <Typography variant="h4" color="textPrimary" textAlign="center">
              {t("notOnlyDataCollection")}
            </Typography>
            <Typography variant="body1" color="textPrimary" textAlign="center">
              {t("clientReportingAndRecommendations")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} order={{xs: 3, md: 4}}>
          <img
            src="/assets/img/about-second.png"
            alt="About Second"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: {xs: "300px", md: "450px"},
              margin: "25px 0",
              objectFit: "contain",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} order={{xs: 5, md: 5}}>
          <img
            src="/assets/img/about-third.png"
            alt="About Third"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: {xs: "300px", md: "450px"},
              margin: "25px 0",
              objectFit: "contain",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} order={{xs: 6, md: 6}} container justifyContent="center" alignItems="center">
          <Stack spacing={3} sx={{alignItems: "center"}}>
            <Typography variant="h4" color="textPrimary" textAlign="center">
              {t("dataDrivenDecisions")}
            </Typography>
            <Typography variant="body1" color="textPrimary" textAlign="center">
              {t("commitmentToClientSuccess")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;