import {lazy, Suspense} from "react";
import {Outlet} from "react-router-dom";
import {AccountInfo} from "src/pages/dashboard/accountinfo";
import {Rewards} from "src/pages/dashboard/rewards";
import {GiftCodesInstructions} from "src/pages/dashboard/giftCodeInstructions";
import {CompletedSurveys} from "src/pages/dashboard/completed-surveys";
import {OpenSurveys} from "src/pages/dashboard/open-surveys";
import GiftCodesFAQ from "src/pages/dashboard/generalFaq";

const DashboardLayout = lazy(() => import("src/layouts/dashboard/dashboard"));
//const OpenSurveys = lazy(() => import("src/pages/dashboard/open-surveys"));
//const CompletedSurveys = lazy(() => import("src/pages/dashboard/completed-surveys"));
//const Rewards = lazy(() => import("src/pages/dashboard/rewards"));

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "open-surveys",
        element: <OpenSurveys />,
      },
      {
        path: "completed-surveys",
        element: <CompletedSurveys />,
      },
      {
        path: "rewards",
        element: <Rewards />,
      },
      {
        path: "accountinfo",
        element: <AccountInfo />,
      },
      {
        path: "howtousegiftcodes",
        element: <GiftCodesInstructions />,
      },
      {
        path: "sss",
        element: <GiftCodesFAQ />,
      },
    ],
  },
];
