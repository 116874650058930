import {useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import AccountInfoUserForm from "./account-info-user-form";
import {ButtonBase, Card, Drawer, IconButton, Tooltip} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import AccountInfoBillingForm from "./account-info-billing-form";
import AccountInfoPasswordForm from "./account-info-password-form";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountInfoCommunicationForm from "./account-info-communication-form";
import {useLocation} from "react-router-dom";

export const AccountInfo = () => {
  const {t} = useTranslation();

  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (location.state?.drawerOpen) {
      setDrawerOpen(location.state?.drawerOpen);
    }
  }, [location]);

  const toggleDrawer = open => () => {
    setDrawerOpen(open);
  };

  const drawerList = [
    {
      key: "accountInfo",
      icon: <AccountCircleIcon fontSize="large" />,
      title: t("editInfo.accountInformation"),
      description: t("editInfo.accountInformationDesc"),
      component: <AccountInfoUserForm />,
    },
    {
      key: "change-password",
      icon: <LockIcon fontSize="large" />,
      title: t("editInfo.changePassword"),
      description: t("editInfo.changePasswordDesc"),
      component: <AccountInfoPasswordForm />,
    },
    {
      key: "communication-preferences",
      icon: <NotificationsIcon fontSize="large" />,
      title: t("editInfo.communicationPreferences"),
      description: t("editInfo.communicationPreferencesDesc"),
      component: <AccountInfoCommunicationForm />,
    },
    {
      key: "payment",
      icon: <PaymentIcon fontSize="large" />,
      title: t("editInfo.paymentInformation"),
      description: t("editInfo.paymentInformationDesc"),
      component: <AccountInfoBillingForm />,
    },
  ];

  const selectedDrawer = drawerList?.find(drawer => drawer?.key === drawerOpen);
  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        <Typography variant="h5" gutterBottom>
          {t("editInfo.editInfo")}
        </Typography>

        {drawerList.map(drawer => {
          return (
            <Tooltip title={drawer.title} arrow>
              <Card
                component={ButtonBase} // Makes the entire card clickable
                sx={{
                  minHeight: 80,
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  px: 4,
                  py: 2,
                  my: 2,
                  transition: "box-shadow 0.2s", // Adds smooth shadow transition
                  "&:hover": {
                    boxShadow: 3, // Shadow on hover
                  },
                  "&:active": {
                    boxShadow: 6, // Deeper shadow when clicked
                  },
                }}
                onClick={() => {
                  setDrawerOpen(drawer.key);
                }}
              >
                {drawer.icon}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    ml: 2,
                    p: 2,
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h6" component="div" sx={{py: 1}}>
                    {drawer.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {drawer.description}
                  </Typography>
                </Box>

                <IconButton disabled aria-label="settings">
                  <ArrowForwardIosIcon />
                </IconButton>
              </Card>
            </Tooltip>
          );
        })}

        <Drawer
          anchor="right"
          open={drawerOpen && selectedDrawer?.component}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              minWidth: isMobile ? "80%" : "40%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              m: 3,
            }}
          >
            <Typography variant="h5">{selectedDrawer?.title}</Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {selectedDrawer?.component}
        </Drawer>
      </Box>
    </Container>
  );
};
