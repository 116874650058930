import axiosInstance from "src/services/axiosInstance";

class UserService {
  async deactivateUser() {
    return await axiosInstance
      .post("/user/deactivate")
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const userService = new UserService();
