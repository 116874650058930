import {ListOptions} from "src/pages/survey/question-factory/multiple-choice-question/options/list-options";
import {DropdownOptions} from "src/pages/survey/question-factory/multiple-choice-question/options/dropdown-options";
import lodash from "lodash";
import {useEffect, useState} from "react";

function prepareOptions(options) {
  const randomOptions = options.filter(item => item.is_random);
  const randomizedOptions = lodash.shuffle(randomOptions);
  const specificIndexItems = options.filter(item => !item.is_random);
  specificIndexItems.forEach(item =>
    randomizedOptions.splice(parseInt(item.index), 0, item),
  );
  return randomizedOptions;
}

export const MultipleChoiceQuestionOptions = props => {
  const {question} = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let options = [];
    if (question.format === "list") {
      options = prepareOptions(question.options);
      if (question.carry_options) {
        options = [...options, ...prepareOptions(question.carry_options)];
      }
    } else if (question.format === "dropdown") {
      options = question.options;
      if (question.carry_options) {
        options = [...options, ...question.carry_options];
      }
    }
    setOptions(options);
  }, []);

  switch (question.format) {
    case "list":
      return <ListOptions question={question} options={options} />;
    case "dropdown":
      return <DropdownOptions question={question} options={options} />;
    default:
      return <>Not Implemented Option Format</>;
  }
};
