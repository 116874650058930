import {questionType} from "src/schemas/survey/question/question-types";

export const questionFactory = question => {
  switch (question.type) {
    case questionType.MultipleChoice:
      return {
        title: question.title,
        format: question.format,
        is_required: question.is_required,
        allow_multiple_answers: question.allow_multiple_answers,
        other_option: question.other_option,
        options: question.options,
        carry_options: question.carry_options,
      };
    case questionType.ShortText:
      return {
        is_required: question.is_required,
        input_type: question.input_type,
      };
    default:
      return {};
  }
};
