import React, {useState} from "react";
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Paper,
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
  Chip,
  useTheme,
  Divider,
  Link,
  Dialog,
  DialogContent,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Alert as MuiAlert,
} from "@mui/material";
import {
  Store,
  AccessTime,
  CreditCard,
  Block,
  CardGiftcard,
  Info,
  LocalOffer,
  ShoppingCart,
  Payment,
  MoneyOff,
  ConfirmationNumber,
  Gavel,
  Refund,
  Close as CloseIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import {useTranslation} from "react-i18next";

const InstructionItem = ({icon: Icon, text, subtitle, chipText}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <ListItem
      sx={{
        mb: 2,
        p: 2,
        borderRadius: 2,
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.02)",
          transform: "translateX(8px)",
        },
      }}
    >
      <ListItemIcon>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50%",
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon sx={{color: "white"}} />
        </Box>
      </ListItemIcon>
      <ListItemText
        primary={
          <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
            <Typography variant="body1" fontWeight="500">
              {text}
            </Typography>
            {chipText && (
              <Chip label={chipText} size="small" color="primary" variant="outlined" />
            )}
          </Box>
        }
        secondary={subtitle}
      />
    </ListItem>
  );
};

const StoreSection = ({store, instructions}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const getStoreLink = (store) => {
    const links = {
      Migros: "https://www.money.com.tr/mc/money-ozelliklerini-kesfet/bireysel-yardim/169#:~:text=Dijital%20Alışveriş%20Kodu%3B%20Migros%20alışverişlerinde,hediye%20çeki%20vb.%20talep%20edilemez.",
      A101: "https://www.a101.com.tr/sss/hediye-ceki-kampanya",
    };
    return links[store];
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 4,
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: "background.paper",
      }}
    >
      <Box mb={4}>
        <Typography variant="h5" gutterBottom fontWeight="bold" color="primary">
          {store} Hediye Kodu Kullanımı
        </Typography>
        <Box sx={{mb: 2}}>
          <Typography variant="subtitle1" fontWeight="medium">
            <Link
              href={getStoreLink(store)}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "#17a7f7",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {store} Resmi Sayfasından {store} Hediye Kodu Kullanım Talimatı
            </Link>
          </Typography>
        </Box>
        <Alert
          severity="info"
          sx={{
            mb: 3,
            borderRadius: 2,
            "& .MuiAlert-icon": {
              alignItems: "center",
            },
          }}
          icon={<Info color="primary" />}
        >
          <AlertTitle sx={{fontWeight: "bold"}}>Önemli Hatırlatma</AlertTitle>
          Kodlar kişiye özel olarak tanımlanmıştır ve tek kullanımlıktır.
        </Alert>
      </Box>

      <List>
        {instructions.map((instruction, index) => (
          <Fade in timeout={500 + index * 100} key={index}>
            <div>
              <InstructionItem {...instruction} />
              {index < instructions.length - 1 && (
                <Divider variant="middle" sx={{my: 2}} />
              )}
            </div>
          </Fade>
        ))}
      </List>
    </Paper>
  );
};

const ContactFormModal = ({open, onClose}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required(t("auth.nameIsRequired")),
    surname: Yup.string().required(t("auth.surnameIsRequired")),
    email: Yup.string().email(t("auth.mustBeValidEmail")).required(t("auth.emailIsRequired")),
    message: Yup.string().required(t("auth.messageIsRequired")),
  });

  const handleSubmit = async (values, {resetForm}) => {
    setLoading(true);
    setMessage(null);
    try {
      await emailjs
        .send("service_exohkma", "template_30tdzvu", values, "A2pgTFc93Cu0aXiC3")
        .then((result) => {
          setMessage({type: "success", text: t("messageSent")});
          resetForm();
        });
    } catch (error) {
      setMessage({type: "error", text: t("messageSendError")});
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" color="primary">
            {t("contactUs")}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {message && (
          <MuiAlert severity={message.type} sx={{mb: 2}}>
            {message.text}
          </MuiAlert>
        )}

        <Formik
          initialValues={{name: "", surname: "", email: "", message: ""}}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({errors, touched}) => (
            <Form>
              <Field
                as={TextField}
                label={t("name")}
                name="name"
                fullWidth
                margin="normal"
                variant="outlined"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <Field
                as={TextField}
                label={t("surname")}
                name="surname"
                fullWidth
                margin="normal"
                variant="outlined"
                error={touched.surname && Boolean(errors.surname)}
                helperText={touched.surname && errors.surname}
              />
              <Field
                as={TextField}
                label={t("mail")}
                name="email"
                type="email"
                fullWidth
                margin="normal"
                variant="outlined"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              <Field
                as={TextField}
                label={t("message")}
                name="message"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                variant="outlined"
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                disabled={loading}
                sx={{mt: 2, fontWeight: "bold", textTransform: "none"}}
              >
                {loading ? t("sending") : t("sendMessage")}
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export const GiftCodesInstructions = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // Contact form state
  const theme = useTheme();
  const {t} = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const migrosInstructions = [
    {
      icon: Store,
      text: "Mağazada ve Online Kullanım",
      subtitle:
        "Migros, 5M Migros, Macrocenter ve Migros Jet mağazalarında ve Sanal Market'te geçerlidir.",
      chipText: "Tüm Kanallar",
    },
    {
      icon: Payment,
      text: "Ödeme Detayları",
      subtitle:
        "Sanal markette kodun %80'i dijital kod ile, kalan %20'si kart ile ödenmelidir.",
      chipText: "80/20 Kural",
    },
    {
      icon: LocalOffer,
      text: "Harcama Limiti",
      subtitle: "Mağazada 200 TL'lik kod için 200 TL'lik harcama yapılabilir.",
      chipText: "Tam Değer",
    },
    {
      icon: Block,
      text: "Kullanım Kısıtlamaları",
      subtitle: "Altın, Gsm TL Yüklemesi ve Toplu ürün alımlarında kullanılamaz.",
      chipText: "Kısıtlamalar",
    },
    {
      icon: AccessTime,
      text: "Süre Sınırı",
      subtitle: "Dijital Alışveriş Kodlarının kullanım süresi bulunmamaktadır.",
      chipText: "Süresiz",
    },
  ];

  const a101Instructions = [
    {
      icon: Store,
      text: "Mağaza Kullanımı",
      subtitle: "Hediye çeki yalnızca A101’in fiziksel mağazalarında geçerlidir.",
      chipText: "Fiziksel Mağaza",
    },
    {
      icon: AccessTime,
      text: "Geçerlilik Süresi",
      subtitle:
        "Hediye çeki, satın alındığı tarihten itibaren 1 ay içinde kullanılmalıdır. Belirtilen süre aşılırsa geçersiz olacaktır.",
      chipText: "1 Ay",
    },
    {
      icon: Block,
      text: "Kullanım Kısıtlamaları",
      subtitle:
        "Elektronik ürünler, cep telefonu TL yüklemeleri ve sigara alımlarında kullanılamaz. Bunun dışında tüm ürünler için geçerlidir.",
      chipText: "Kısıtlamalar",
    },
    {
      icon: MoneyOff,
      text: "Nakit ve Para Üstü",
      subtitle: "Hediye çeki nakit olarak bozdurulamaz, para üstü verilmez ve tek seferde kullanılmalıdır.",
      chipText: "Nakde Çevirim Yok",
    },
    {
      icon: ConfirmationNumber,
      text: "Kod Kullanımı",
      subtitle:
        "Mağaza alışverişlerinde birden fazla hediye çeki kodu bir arada kullanılabilir.",
      chipText: "Çoklu Kod",
    },
    {
      icon: Gavel,
      text: "Hak Sahipliği",
      subtitle:
        "Sanal hediye çekleri, kodu ibraz eden kişiye aittir. Sanal çekle yapılan alışveriş iade edildiğinde nakit ödeme yapılmaz; bunun yerine aynı tutarda yeniden alışveriş yapılabilir.",
      chipText: "Hamiline Çek",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{py: 6}}>
      <Box textAlign="center" mb={6}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          fontWeight="bold"
          color="primary"
          sx={{
            fontSize: {xs: "2rem", md: "3rem"},
            mb: 3,
          }}
        >
          {t("faq.howToUseGiftCodes")}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{maxWidth: "600px", mx: "auto", mb: 4}}
        >
          Hediye kodlarınızı kullanmadan önce lütfen aşağıdaki talimatları dikkatlice
          okuyunuz.
        </Typography>
      </Box>

      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          overflow: "hidden",
          border: `1px solid ${theme.palette.divider}`,
          mb: 4,
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            "& .MuiTab-root": {
              py: 2,
              px: 4,
              fontWeight: "medium",
            },
          }}
        >
          <Tab label="Migros" icon={<ShoppingCart />} iconPosition="start" />
          <Tab label="A101" icon={<Store />} iconPosition="start" />
        </Tabs>

        <Box sx={{p: {xs: 2, md: 4}}}>
          {tabValue === 0 && (
            <Fade in timeout={500}>
              <div>
                <StoreSection store="Migros" instructions={migrosInstructions} />
              </div>
            </Fade>
          )}
          {tabValue === 1 && (
            <Fade in timeout={500}>
              <div>
                <StoreSection store="A101" instructions={a101Instructions} />
              </div>
            </Fade>
          )}
        </Box>
      </Paper>
      <Typography
        color="text.secondary"
        sx={{mx: "auto", textAlign: "center"}}
      >
        Talepleriniz 5 iş günü içerisinde işleme alınacaktır.
      </Typography>

      {/* Contact Us Section */}
     <Box
        sx={{
          textAlign: "center",
          mt: 4,
          p: 3,
          bgcolor: "primary.main",
          borderRadius: 3,
          color: "white",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Yardıma mı ihtiyacınız var?
        </Typography>
        <Typography variant="body2" sx={{ display: "inline" }}>
          Hediye kodu kullanımı ile ilgili sorularınız için{" "}
          <Button
            sx={{
              color: "white",
              textDecoration: "underline",
              textTransform: "none",
              fontWeight: "bold",
              display: "inline",
              minWidth: "auto",
              padding: 0, 
              "&:hover": { textDecoration: "none" },
            }}
            onClick={() => setIsContactFormOpen(true)}
          >
            ulaşabilirsiniz.
          </Button>
        </Typography>
      </Box>

      {/* Contact Form Modal */}
      <ContactFormModal
        open={isContactFormOpen}
        onClose={() => setIsContactFormOpen(false)}
      />
    </Container>
  );
};

export default GiftCodesInstructions;
