import {questionType} from "src/schemas/survey/question/question-types";
import CheckMultipleChoiceAnswer from "src/pages/survey/check-answers/check-multiple-choice-answer";
import CheckShortTextAnswer from "src/pages/survey/check-answers/check-short-text-answer";

export default function CheckQuestionAnswer(question, answer) {
  switch (question.type) {
    case questionType.MultipleChoice:
      return CheckMultipleChoiceAnswer(question, answer);
    case questionType.ShortText:
      return CheckShortTextAnswer(question, answer);
    default:
      return true;
  }
}
